<template>
  <div>
    <div class="row">
      <div class="input-field col s12 m6">
        <i class="fas fa-user prefix grey-text"></i>
        <input 
          name="first_name"
          id="first_name"
          v-model="firstName"
          type="text"
          lang="en-001">
        <span class='red-text small-font' v-show='errors.first("first_name")'>{{errors.first("first_name")}}</span>
        <label for="first_name">First Name (Optional)</label>
      </div>

      <div class="input-field col s12 m6">
        <i class="fas fa-user prefix grey-text"></i>
        <input 
          name="last_name"
          id="last_name"
          v-model="lastName"
          type="text"
          lang="en-001">
        <span class='red-text small-font' v-show='errors.first("last_name")'>{{errors.first("last_name")}}</span>
        <label for="last_name">Last Name (Optional)</label>
      </div>

      <div class="input-field col s12 m6">
        <i class="fas fa-building prefix grey-text"></i>
        <input 
          name="company"
          id="company"
          v-model="company"
          type="text"
          lang="en-001">
        <label for="company">Company (Optional)</label>
      </div>

      <div class="input-field col s12 m6">
        <i class="fas fa-phone prefix grey-text"></i>
        <input 
          name="phone"
          id="phone"
          v-model="phone"
          type="text"
          inputmode="tel">
        <label for="phone">Phone (Optional)</label>
      </div>

      <div class="input-field col s12 m6">
        <i class="fas fa-envelope prefix grey-text"></i>
        <input 
          name="email"
          id="email"
          v-model="email"
          type="email"
          v-validate="'email'"
          lang="en-001">
        <span class='red-text small-font' v-show='errors.first("email")'>{{errors.first("email")}}</span>
        <label for="email">Email (Optional)</label>
      </div>

      <div class="input-field col s12 m6">
        <i class="fas fa-sticky-note prefix grey-text"></i>
        <input 
          name="note" 
          id="note" 
          type="text" 
          v-model="note" 
          ref="note">
        <label for="note">Note (Optional)</label>
      </div>
    </div>
    <slot :update-token="updateToken"></slot>

    <center class='grey-text mt-25' v-if="pending">
      <i class="fas fa-spinner fa-spin"></i> Processing...
    </center>

    <button class="btn-large wide-btn mt-50" :disabled="!cardToken" @click="createCardOnFile" v-else>
      <i class="fas fa-lock"></i> 
      Securely Store Card
    </button>
  </div>
</template>
<script>
  import gql from 'graphql-tag'

  export default {
    data() {
      return {
        cardToken: null,
        firstName: null,
        lastName: null,
        company: null,
        phone: null,
        email: null,
        note: null,
        pending: false
      }
    },
    methods: {
      createCardOnFile() {
        this.pending = true
        this.$apollo.mutate({
          mutation: gql`
            mutation CreateCardOnFile($token: String!, $firstName: String, $lastName: String, $company: String, $phone: String, $email: String, $note: String) {
              createCardOnFile(input: {
                token: $token,
                firstName: $firstName,
                lastName: $lastName,
                company: $company,
                phone: $phone,
                email: $email,
                note: $note
              }) { customer { id } errors }
            }
          `,
          variables: {
            token: this.cardToken,
            firstName: this.firstName,
            lastName: this.lastName,
            company: this.company,
            phone: this.phone,
            email: this.email,
            note: this.note
          },
        }).then(result => {
          if (result.data.createCardOnFile.errors.length === 0) {
            Turbolinks.visit("/merchant/cards")
          } else {
            this.pending = false
            this.notifyError(result.data.createCardOnFile.errors[0])
          }
        }, error => {
          this.pending = false
          this.gqlErrorHandler(error)
        });
      },
      updateToken(token) {
        this.cardToken = token.token
        this.funding = token.funding
        if (token.token) {
          this.updateAmounts()
        }
      }
    }
  }
</script>
<style>
</style>
