<template>
  <div>
    <div class="row">
      <div class="col s12 m4">
        <label for="cardNumber">Card Number</label>
        <div id="cardNumber" ref="cardNumber" class="stripe-input"></div>
      </div>

      <div class="col s4 m2">
        <label for="cardExpiry">Exp Date</label>
        <div id="cardExpiry" ref="cardExpiry" class="stripe-input"></div>
      </div>

      <div class="col s4 m2">
        <label for="cardCvc">CVC</label>
        <div id="cardCvc" ref="cardCvc" class="stripe-input"></div>
      </div>

      <div class="col s4 m2">
        <label for="addressZip">{{ zipLabel }}</label>
        <input id="addressZip" ref="addressZip" class="zip-input" type="text" v-mask="zipMask" v-model="addressZip" :style="{ color: foregroundColor }"
          placeholder="90210" v-validate="'required|length:' + zipLength" @keyup="onZipChange" data-vv-name="postalCode" />
      </div>
    </div>

    <div id="card-errors" class="red-text text-darken-3">{{ cardError }}</div>
    <span class='red-text'>{{ errorMessage }}</span><br>
    <span class='red-text'>{{ processingErrorMessage }}</span><br>
  </div>
</template>
<script>
import gql from 'graphql-tag'

export default {
  props: {
    publishableKey: {
      type: String,
      required: true
    },
    presetAddressZip: {
      type: String,
      default: ''
    },
    foregroundColor: {
      type: String,
      default: '#32325d'
    },
  },
  mounted() {
    this.loadStripeJs()
  },
  data() {
    return {
      errorMessage: null,
      processingErrorMessage: null,
      cardError: null,
      cardNumberComplete: false,
      cardExpiryComplete: false,
      cardCvcComplete: false,
      postalCodeComplete: false,
      currentCardBrand: '',
      addressZip: this.presetAddressZip || ""
    }
  },
  methods: {
    loadStripeJs() {
      this.script = document.createElement('script')
      this.script.setAttribute('src', 'https://js.stripe.com/v3/')
      document.head.appendChild(this.script)
      this.script.onload = this.setupStripe
    },

    setupStripe() {
      this.stripe = Stripe(this.publishableKey);
      this.elements = this.stripe.elements({ locale: "en" });

      // Create individual elements for card number, expiry, CVC, and ZIP
      this.cardNumber = this.elements.create("cardNumber", {
        disableLink: true,
        style: {
          base: {
            color: this.foregroundColor,
            fontWeight: "500",
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "14px",
            iconColor: "#c4f0ff",
            "::placeholder": { color: "#aab7c4" },
          },
          invalid: { color: "#fa755a", iconColor: "#fa755a" },
        },
      });

      this.cardExpiry = this.elements.create("cardExpiry", {
        style: {
          base: {
            color: this.foregroundColor,
            fontWeight: "500",
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "14px",
            "::placeholder": { color: "#aab7c4" },
          },
          invalid: { color: "#fa755a" },
        },
      });

      this.cardCvc = this.elements.create("cardCvc", {
        style: {
          base: {
            color: this.foregroundColor,
            fontWeight: "500",
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "14px",
            "::placeholder": { color: "#aab7c4" },
          },
          invalid: { color: "#fa755a" },
        },
      });
      // Mount each element to its respective container
      this.cardNumber.mount(this.$refs.cardNumber);
      this.cardExpiry.mount(this.$refs.cardExpiry);
      this.cardCvc.mount(this.$refs.cardCvc);

      // Listen for validation errors or changes
      this.cardNumber.on("change", this.onChange);
      this.cardExpiry.on("change", this.onChange);
      this.cardCvc.on("change", this.onChange);
    },
    onChange({ error, elementType, complete, brand }) {
      if (error) {
        this.cardError = error.message;
      } else {
        this.cardError = null;
        // Update completion status based on elementType
        if (elementType === 'cardNumber') {
          this.cardNumberComplete = complete;
          if (brand) {
            this.currentCardBrand = brand;
          }
        } else if (elementType === 'cardExpiry') {
          this.cardExpiryComplete = complete;
        } else if (elementType === 'cardCvc') {
          this.cardCvcComplete = complete;
        }

        // When an input field is complete, focus the next one
        if (complete) {
          this.focusNextField(elementType)
        }

        this.emitToken();
      }
    },
    onZipChange() {
      this.$validator.validate('postalCode', this.addressZip).then((valid) => {
        this.postalCodeComplete = valid;
        this.emitToken();
      });
    },
    emitToken() {
      // Check if all fields are complete and create token
      if (this.cardNumberComplete && this.cardExpiryComplete &&
        this.cardCvcComplete && this.postalCodeComplete) {
        this.stripe.createToken(this.cardNumber, { address_zip: this.addressZip }).then(this.resultHandler);
      } else {
        this.$emit('token-generated', {token: null, funding: null});
      }
    },
    focusNextField(elementType) {
      const focusOrder = ['cardNumber', 'cardExpiry', 'cardCvc', 'zip'];
      const currentIndex = focusOrder.indexOf(elementType);
      const nextIndex = currentIndex + 1;

      const nextElementKey = focusOrder[nextIndex];
      const nextElement = this[nextElementKey];

      if (elementType === 'cardCvc') {
        if (this.currentCardBrand !== 'amex') {
          this.$refs.addressZip.focus();
        }
      } else {
        if (nextElement) {
          nextElement.focus()
        }
      }
    },
    resultHandler(result) {
      if (result.error) {
        this.notifyError(result.error.message)
        this.processingErrorMessage = result.error.message
      } else {
        this.createStripeToken(result.token);
      }
    },
    createStripeToken(token) {
      this.$http.post("/api/v1/public/stripe_tokens", {
        token: token.id,
        last4: token.card.last4
      }).then(response => {
          this.$emit('token-generated', {token: response.data.token, funding: token.card.funding});
        }, error => {
            this.notifyError("An error occurred while sending the token.");
            this.processingErrorMessage = "An error occurred while sending the token.";
          })
    }
  },
  computed: {
    isPartialCanadianZip() {
      return /^[A-Za-z]/.test(this.addressZip);
    },
    isPartialUSZip() {
      return /^[0-9]{1,5}(-[0-9]{1,4})?$/.test(this.addressZip);
    },
    zipLength() {
      if (this.isPartialCanadianZip) {
        return 7
      } else if (this.isPartialUSZip) {
        return 5
      } else {
        return 1000
      }
    },
    zipLabel() {
      if (this.isPartialCanadianZip) {
        return "Zip (CAN)";
      } else if (this.isPartialUSZip) {
        return "Zip (US)";
      } else {
        return "Zip";
      }
    },
    zipMask() {
      if (this.isPartialCanadianZip) {
        return "A#A #A#";
      } else if (this.isPartialUSZip) {
        return "#####";
      } else {
        // return "XXXXXXXXXXXXXXXXX";
        return "#####";
      }
    }
  }
}
</script>
<style scoped>
.stripe-input {
  display: block;
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.zip-input {
  display: block !important;
  margin-top: 5px !important;
  padding: 10px !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  box-sizing: border-box !important;
  outline: none !important;
  height: auto !important;
}

.zip-input::placeholder {
  color: #AAB7C4;
}

/* Adding vendor prefixes for broader browser compatibility */
.zip-input::-webkit-input-placeholder {
  /* Chrome, Safari, Opera */
  color: #AAB7C4;
}

.zip-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #AAB7C4;
  opacity: 1;
  /* Firefox reduces the opacity of placeholders by default */
}

.zip-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #AAB7C4;
}

.zip-input:-moz-placeholder {
  /* Firefox 18- */
  color: #AAB7C4;
  opacity: 1;
}

.zip-input:focus {
  border-bottom: 1px solid #ccc !important;
  box-shadow: none !important;
}

/* Add unfocus on error */
.zip-input.invalid {
  border-bottom: 1px solid #ccc !important;
  box-shadow: none !important;
}

.zip-input.valid {
  border-bottom: 1px solid #ccc !important;
  box-shadow: none !important;
}
</style>
