<script>
  import gql from 'graphql-tag'

  export default {
    props: {
      gid: {
        type: String,
        required: false
      },
      label: {
        type: String,
        required: true
      },
      subLabel: {
        type: String,
        required: false
      },
      onLabel: {
        type: String,
        default: "On"
      },
      offLabel: {
        type: String,
        default: "Off"
      },
      settingField: {
        type: String,
        required: true
      },
      initialState: {
        type: Boolean,
        required: true
      },
      mutationAttribute: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        settings: {
          [this.settingField]: this.initialState
        }
      }
    },
    methods: {
      switchState() {
        const settingsInput = {
          [this.mutationAttribute]: !this.settings[this.settingField]
        };

        this.$apollo.mutate({
          mutation: gql`
            mutation updatePaySettings($input: PayAttributes!, $gid: ID) {
              updatePaySettings(input: {settings: $input, userId: $gid}) {
                paySettings {
                  ${this.settingField}
                }
                errors
              }
            }
          `,
          variables: {
            input: settingsInput,
            gid: this.gid
          },
        }).then(result => {
          if (result.data.updatePaySettings.errors.length > 0) {
            this.notifyError(result.data.updatePaySettings.errors[0])
            this.settings[this.settingField] = !this.settings[this.settingField]
          } else {
            this.settings[this.settingField] = result.data.updatePaySettings.paySettings[this.settingField]
            this.notifySuccess("Settings successfully updated")
          }
        }).catch(error => {
          this.gqlErrorHandler(error)
          console.log(error)
          this.settings[this.settingField] = !this.settings[this.settingField]
        })
      }
    }
  }
</script>

<template>
  <div>
    <section>
      <div class= "very-close-lines">
        {{ label }}<br>
        <span v-if="subLabel">{{ subLabel }}</span>
      </div>
      <!-- Switch -->
      <div class="switch mt-5">
        <label>
          {{ offLabel }}
          <input type="checkbox" @click='switchState' v-model='settings[settingField]' :disabled="$apollo.loading">
          <span class="lever"></span>
          {{ onLabel }}
        </label>
      </div>
    </section>
  </div>
</template>
