<template>
  <div>
    <section class="main-form" v-if="!showItemForm">
      <div class="row" v-if="items.length == 0">
        <div class="input-field col s12 m6">
          <i class="fas fa-dollar-sign prefix grey-text"></i>
          <input
            name="amount"
            id="amount"
            @input="updateAmounts"
            autofocus
            v-model='amount'
            step="0.01"
            type="text" 
            inputmode="decimal"
            v-validate="'min_value:0.01|required'"
            class='inputText'
            lang="en-001">

          <label for="amount">Amount</label>
        </div>

        <div class="input-field col s12 m6">
          <i class="fas fa-file-invoice prefix grey-text"></i>
          <input v-model="invoice" id="invoice" type="text" class="validate" ref="invoice">
          <label for="invoice">{{invoiceLabel}}</label>
        </div>
        <div class="input-field col s12 m6" v-if="requireInvoiceConfirmation">
          <i class="fas fa-file-invoice prefix grey-text"></i>
          <input name="invoice-cfm" id="invoice-cfm" type="text" v-validate="{required: invoice.length > 0, confirmed: 'invoice'}">

          <label for="invoice-cfm">Invoice (confirmation)</label>
          <span v-show="errors.has('invoice-cfm')" class="red-text">Invoice numbers do not match</span>
        </div>
        <div class="input-field col s12 m6">
          <i class="fas fa-sticky-note prefix grey-text"></i>
          <input v-model="note" id="note" type="text" class="validate">
          <label for="note">Note</label>
        </div>
      </div>
      <div v-show="items.length > 0">
        <div class="col s12 m6">
          <span class="items-amount bold-font emerald">{{amount | currency}}</span>
        </div>
        <div class="col s12 m6">
          <show-items :items="items" :items-updated="itemsUpdated"></show-items>
        </div>
      </div>

      <button class="btn btn-small outlined-btn mt-10" @click="showItemForm = true" v-show='!showPending'>
        <i class="fas fa-plus"></i>

        Add Item
      </button>

      <center>
        <section class='mt-10' v-if='showTips'>
          <tips :qid="qid" :tips-updated="tipsUpdated"></tips>
        </section>
      </center>

      <button class="btn-large wide-btn mt-10" type="submit" name="action" @click="validateAndSubmit" :disabled="!submittable" v-if="!processing">
        Record {{grossAmount | currency}}
      </button>

      <div v-else class='grey-text center mt-10'>
        <i class="fas fa-spinner fa-spin grey-text"></i> 
        Processing ...
      </div>

      <div class="center grey-text mt-5" v-if="feeAmount > 0">
        Gross amount: <b>{{grossAmount | currency}}</b> |  
        Processing fee: <b>{{feeAmount | currency}}</b>
      </div>
    </section>

    <div v-if="showItemForm">
      <items
        :items="items"
        :items-updated="itemsUpdated"
        :set-invoice="invoice"
        :set-note="note"
        :set-amount="items.length > 0 ? null : amount"
        :invoice-label="invoiceLabel"
        :require-invoice-confirmation="requireInvoiceConfirmation"
        @close="showItemForm=false">

      </items>
    </div>
  </div>
</template>

<script>
  import gql from 'graphql-tag'
  import tips from "../../tips"
  import items from '../generic_cards/items'
  import showItems from '../generic_cards/show_items'

  export default {
    components: {tips, items, showItems},
    props: ['qid', 'showTips', 'requireInvoiceField', 'requireInvoiceConfirmation'],
    data() {
      return {
        amount: null,
        note: null,
        invoice: "",
        items: [],
        showItemForm: false,
        showPending: false,
        grossAmount: 0,
        feeAmount: 0,
        withTipAmount: 0,
        lastGrossCalcRequestId: 0,
        processing: false
      };
    },
    methods: {
      updateAmounts() {
        this.lastGrossCalcRequestId++
        let requestId = this.lastGrossCalcRequestId
        this.$apollo.query({
          query: gql`query ($amount: Float!, $tipPercent: Int, $tipCustomAmount: Float) {
            merchant {
              grossAchAmountCalc(amount: $amount, tipPercent: $tipPercent, tipCustomAmount: $tipCustomAmount) {
                grossAmount
                tip
                amountWithTip
                fee
              }
            }
          }`,
          variables: {
            amount: parseFloat(this.amount) || 0,
            tipPercent: parseInt(this.selectedTip),
            tipCustomAmount: parseFloat(this.tipCustomAmount)
          }
        }).then(response => {
          if(requestId == this.lastGrossCalcRequestId) {
            this.grossAmount = response.data.merchant.grossAchAmountCalc.grossAmount
            this.feeAmount = response.data.merchant.grossAchAmountCalc.fee
            this.withTipAmount = response.data.merchant.grossAchAmountCalc.amountWithTip
          }
        })
      },
      tipsUpdated(selectedTip, tipCustomAmount) {
        this.selectedTip = selectedTip
        this.tipCustomAmount = tipCustomAmount
        this.updateAmounts()
      },
      itemsUpdated(amount) {
        this.amount = amount
        this.invoice = ""
        this.note = ""
        this.updateAmounts()
      },
      validateAndSubmit() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.submit()
          } else {
            this.notifyError("Please complete all required fields!")
          }
        })
      },
      submit() {
        this.processing = true
        this.$apollo.mutate({
          mutation: gql`
            mutation($amount: Float!, $note: String, $invoice: String, $tip_percent: Int, $tip_amount: Float, $items: [ItemAttributes!]) {
              chargeCash(input: {amount: $amount, note: $note, invoice: $invoice, items: $items, tipPercent: $tip_percent, tipAmount: $tip_amount}) {
                transaction {
                  authCode
                  deprecatedId
                }
                errors
              }
            }
          `,
          variables: {
            amount: parseFloat(this.amount),
            note: this.note,
            invoice: this.invoice,
            tip_percent: parseInt(this.selectedTip),
            tip_amount: parseFloat(this.tipCustomAmount),
            items: this.items
          }
        }).then(response => {
          if (response.data.chargeCash.errors.length > 0) {
            this.processing = false;
            this.notifyError(response.data.chargeCash.errors[0]);
          } else {
            window.location.href = '/client/ach_transactions/' + response.data.chargeCash.transaction.deprecatedId
          }
        }).catch(error => {
          this.processing = false;
          console.error('Error submitting transaction:', error);
          this.gqlErrorHandler(error)
        });
      }
    },
    computed: {
      paramsGrossCalc() {
        return {
          amount: this.amount,
          tip_percent: this.selectedTip,
          tip_custom_amount: this.tipCustomAmount,
          qid: this.qid
        }
      },
      invoiceLabel() {
        return this.requireInvoiceField ? "Invoice # (required)" : "Invoice # (optional)" 
      },
      submittable() {
        return (
          this.amount != null && 
          this.amount > 0
        );
      }
    }
  }
</script>
<style scoped>
  .items-amount {
    font-size: 3em !important;
  }
  .mb--10 {
    margin-bottom: -30px !important;
  }
</style>
