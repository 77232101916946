<template>
  <div>
    <!-- Submit button section -->
    <section v-show="!allowSignAutosubmit">
      <button @click="charge" v-show="!showPending && allowPayByCardInput" class="btn-large wide-btn mt-5" :disabled="!cardToken || disableSubmit">
        {{$t("sendBill.pay")}}<span v-show='amountNumber'> {{withTipAmount}}</span>
      </button>
    </section>

    <section class="center" v-show="allowSignAutosubmit && showTips">
      <h3 class="bold-font emerald">{{$t("sendBill.withTipAmount")}} {{withTipAmount}}</h3>
    </section>
  </div>
</template>
<script>
  import { useCardsBillStore } from "./store"
  import { toRefs } from '@vue/composition-api'

  export default {
    setup: () => ({ ...toRefs(useCardsBillStore()) }),
    created() {
      this.$bus.$on('submitOnSignatureFinished', this.chargeIt);
    },
    props: [
      'linkOrderId',
      'policyUrl',
      'showTips',
      'showVote',
      'showReview',
      'showFeedbackHub',
      'useNpsVote'
    ],
    methods: {
      chargeIt() {
        this.allowSignAutosubmit = false
        this.charge()
      },
      charge() {
        if(this.tipAmount < 20 || confirm(`Confirm ${this.tipAmountFormatted} tip amount`)){
          this.disableSubmit = true
          this.showPending = true
          this.resubmitFlow = true

          this.$http.post("/api/v1/app/client/link_orders/generic_card_transactions", this.params).then(response => {
            this.transactionId = response.body.payment.data.id
            this.votesUrl = response.body.payment.data.attributes.votes_url
            Turbolinks.visit(
              "/a/thank_you?transaction_id=" +
                this.transactionId +
                "&url=" + this.votesUrl +
                "&locale=" + this.$i18n.locale +
                "&show_vote=" + this.showVote +
                "&show_review=" + this.showReview +
                "&show_feedback_hub=" + this.showFeedbackHub +
                "&use_nps_vote=" + this.useNpsVote
            )
          }, error => {
            this.errorHandler(error)
            this.showPending = false
            this.disableSubmit = false
            this.disableSignupBtn = false
            this.cardToken = null

            if(error.status == 422) {
              this.errorMessage = error.body.error_messages[0]
            } else {
              this.errorMessage = "Something went wrong"
            }
          })
        }
      }
    },
    computed: {
      params() {
        return {
          token: this.cardToken.token,
          link_order_id: this.linkOrderId,
          tip: this.selectedTip,
          tip_amount: this.tipCustomAmount,
          signature_image: this.signatureImage
        }
      }
    }
  }
  </script>
