<!--
  publicCardElement allows to tokenise card without sign in (it used eg for send
  a bill flow when client is processing payment by link without signin. Merchant
  is defined by qid param, secure UUID string and request allowed for those merchants
  who is connected to stripe (has stripe_business_account)

  For signed in users - genericCardElement, powered by GraphQL
-->
<template>
  <div>
    <div class="row">
      <div class="red-text text-darken-3">{{errorMessage}}</div>
      <div>
        <card-input :submit="submit" :reset-token="resetToken" :presetAddressZip="presetAddressZip"></card-input>
        <div v-show="creatingToken" class="center">
          <i class="fas fa-spinner fa-spin grey-text"></i>
          Verifying data
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Base64 from 'crypto-js/enc-base64';
  import WordArray from 'crypto-js/lib-typedarrays';
  import AES from 'crypto-js/aes';
  import { publicEncrypt } from 'crypto'
  import cardInput from '../../generic_cards/card_input'

  export default {
    components: {cardInput},
    props: ['qid', 'presetAddressZip'],
    created() {
      this.getRsaKey()
    },
    data() {
      return {
        rsa: {},
        number: null,
        expMonth: null,
        expYear: null,
        verificationNumber: null,
        addressZip: null,
        errorMessage: null,
        creatingToken: false
      }
    },
    methods: {
      submit(cardJSON) {
        this.creatingToken = true
        let key = WordArray.random(16);
        let iv = WordArray.random(16);
        let encryptedCardPayload = AES.encrypt(cardJSON, key, { iv: iv })

        let aesKeyBase64 = Base64.stringify(encryptedCardPayload.key)
        let ivBase64 = Base64.stringify(encryptedCardPayload.iv)

        let buffer = new Buffer(aesKeyBase64)
        let encryptedAesKey = publicEncrypt(this.rsa.key, buffer).toString('base64')
        this.createToken(ivBase64, encryptedAesKey, encryptedCardPayload.toString())
      },
      createToken(iv, key, payload) {
        let params = {
          encrypted_aes_key: key,
          iv: iv,
          encrypted_card_data: payload,
          public_key_id: this.rsa.keyId,
          qid: this.qid
        }
        this.$http.post("/api/v1/public/card_tokens", params).then(response => {
          this.errorMessage = null
          this.creatingToken = false
          document.activeElement.blur();
          window.scrollTo(0,0)
          this.$emit("token-generated", { token: response.body.token, funding: response.body.funding })
        }, error => {
          this.creatingToken = false
          this.$emit("token-generated", {token: null, funding: null})
          this.errorHandler(error)
        })
      },
      resetToken() {
        this.$emit("token-generated", {token: null, funding: null})
      },
      getRsaKey() {
        this.$http.post("/api/v1/public/rsa_keys").then(response => {
          this.rsa = {
            keyId: response.body.key_id,
            key: response.body.key
          }
        }, error => {
          this.notifyError("Something went wrong")
        })
      }
    }
  }
</script>

