<template>
  <div class="container" id="adyen-checkout">
    <h3 class="center-align bold-font">Adyen Card Tokenization Test!</h3>
    Client Key: {{ clientKey }}<br>
    Session ID: {{ sessionId }}<br>
    Session Data: {{ sessionData }}<br>
    ENV: {{ env }}<br>

    <div ref="dropinContainer"></div>
  </div>
</template>

<script>
import { AdyenCheckout, Dropin, Card } from "@adyen/adyen-web";
import '@adyen/adyen-web/styles/adyen.css';

export default {
  props: ["sessionId", "sessionData", "clientKey", "env", "amountCents"],
  mounted() {
    this.initializeAdyenCheckout();
  },
  methods: {
    async initializeAdyenCheckout() {
      const globalConfiguration = {
        session: {
          id: this.sessionId, // Use sessionId from props
          sessionData: this.sessionData, // Use sessionData from props
        },
        amount: {
          value: this.amountCents, // Use amountCents from props
          currency: "USD", // Use USD as currency
        },
        locale: "en-US", // Locale set to US English
        countryCode: "US", // Country set to United States
        clientKey: this.clientKey, // Replace with your actual test client key
        environment: this.env, // Change to 'live' for production
        onPaymentCompleted: (result, component) => {
          console.info("Payment Completed:", result);
        },
        onPaymentFailed: (result, component) => {
          console.info("Payment Failed:", result);
        },
        onError: (error, component) => {
          console.error("Error:", error.name, error.message, error.stack, component);
        },
      };

      try {
        const checkout = await AdyenCheckout(globalConfiguration);

        const dropinConfiguration = {
          paymentMethodComponents: [Card],
          paymentMethodsConfiguration: {
            card: {
              hasHolderName: false,
              billingAddressRequired: false,
              holderNameRequired: false,
            },
          },
        };

        new Dropin(checkout, dropinConfiguration).mount(this.$refs.dropinContainer);
      } catch (error) {
        console.error("Failed to initialize Adyen Checkout:", error);
      }
    },
  },
};
</script>

<style scoped>
#adyen-checkout {
  max-width: 600px;
  margin: 20px auto;
}

#dropin-container {
  margin-top: 20px;
}
</style>
