<template>
  <div>
    <div class="btn-large wide-btn rounded-btn mt-50" @click="pay" v-if="!showOnePaymentOptionForm && !showForm">
      <span style='margin-right: 10px'>{{$t("sendBill.payWithCard")}}</span>
    </div>
    <section v-else>
      <div v-show="loadingStripeJS">
        <center class='grey-text mt-5'>
          <i class="fas fa-spinner fa-spin"></i> {{$t("sendBill.loading")}}
        </center>
      </div>

      <div class="col s12 mt-10" v-show="!loadingStripeJS">
        <tips-block v-bind="$props"></tips-block>

        <section class="card-container mt-25" v-show="(allowPayByCardInput && !cardToken.token) || (resubmitFlow && !showPending)">
          <center><label>{{$t("sendBill.fillInCardDetails")}}:</label></center>
          <div v-if="genericFlow">
            <generic-card-element :qid="merchantQid" @token-generated="updateToken" class="mt-25"></generic-card-element>
          </div>

          <div v-else>
            <stripe-card-element
              :publishable-key="stripePublishableKey"
              @token-generated="updateToken"></stripe-card-element>
          </div>

          <span class='red-text'>{{errorMessage}}</span>
        </section>

        <!-- Card token -->
        <center>
          <div class='mt-10 signature' v-if="allowPayByCardInput && !errorMessage && cardToken.token && !resubmitFlow">
            <signature-pad @onBegin='onBegin' :policy-url="policyUrl" :allow-sign-autosubmit="allowSignAutosubmit"></signature-pad>
          </div>
        </center>

        <submit-button v-if="cardToken.token || resubmitFlow" v-bind="$props"></submit-button>
        <apple-google-pay v-if="canAppleGooglePay" v-bind="$props"></apple-google-pay>

        <!-- Surcharge eligible fee notice section -->
        <div class='grey-text mt-5 center' v-show="feeAmount != 0">
          {{$t("sendBill.grossAmount")}}: <b>{{grossAmount | currency}}</b> |  
          <i class="far fa-credit-card"></i> {{$t("sendBill.nonCashAdj")}}: <b>{{feeAmount | currency}}</b>
        </div>
      </div>
    </section>

    <center v-if='showPending' class='grey-text mt-25'>
      <i class="fas fa-spinner fa-spin"></i> {{$t("sendBill.processing")}}
    </center>
  </div>
</template>
<script>
  import { useCardsBillStore } from "./cc/store"
  import { toRefs } from '@vue/composition-api'

  import tipsBlock from "./cc/tips_block"
  import submitButton from "./cc/submit_button"
  import appleGooglePay from "./cc/apple_google_pay"
  import genericCardElement from '../../merchant/cards/elements/public_generic_card_element'
  import stripeCardElement from '../../merchant/cards/elements/stripe_card_element'
  import signaturePad from "./cc/signature_pad"
  import { useBaseStore } from "./base_store"

  export default {
    setup() {
      const cardsBillStore = useCardsBillStore();
      const baseStore = useBaseStore();

      return { ...toRefs(cardsBillStore), ...toRefs(baseStore) }
    },
    components: { signaturePad, tipsBlock, genericCardElement, stripeCardElement, submitButton, appleGooglePay },
    props: [
      'stripePublishableKey',
      'linkOrderId',
      'merchantName',
      'showTips',
      'policyUrl',
      'merchantVotesUrl',
      'canAppleGooglePay',
      'merchantStripeId',
      'showOnePaymentOptionForm',
      'showVote',
      'showReview',
      'showFeedbackHub',
      'useNpsVote',
      'genericFlow'
    ],
    data() {
      return {
        showForm: false
      }
    },
    methods: {
      pay() {
        this.selectedFlow = ['cc']
        this.showForm = true
        if(!this.showTips) {
          this.updateAmounts()
        }
      },
      onBegin() {
        this.disableSubmit = false
      },
      updateToken(token) {
        this.cardToken = token
        if (token.token) {
          this.updateAmounts()
        }
      }
    }
  }
</script>
<style scoped>
  .card-input {
    border: 2px solid #00ab8e;
  }
  .card-container {
    margin-left: 3px;
    margin-right: 3px;
  }
  .signature {
    width: 100%;
  }
</style>
