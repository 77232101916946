<template>
  <div>
    <div class="center">
      <span class="btn-range tooltipped" :class="{'active-btn': filter == 'TODAY'}" @click="filter = 'TODAY'" data-position="top" data-tooltip="Today">TD</span>
      <span class="btn-range tooltipped" :class="{'active-btn': filter == 'WTD'}" @click="filter = 'WTD'" data-position="top" data-tooltip="Week To Date">WTD</span>
      <span class="btn-range tooltipped" :class="{'active-btn': filter == 'MTD'}" @click="filter = 'MTD'" data-position="top" data-tooltip="Month To Date">MTD</span>
      <span class="btn-range tooltipped" :class="{'active-btn': filter == 'YTD'}" @click="filter = 'YTD'" data-position="top" data-tooltip="Year To Date">YTD</span>
      <span class="btn-range tooltipped" :class="{'active-btn': filter == 'YST'}" @click="filter = 'YST'" data-position="top" data-tooltip="Past Day">PD</span>
      <span class="btn-range tooltipped" :class="{'active-btn': filter == 'PW'}" @click="filter = 'PW'" data-position="top" data-tooltip="Past Week">PW</span>
      <span class="btn-range tooltipped" :class="{'active-btn': filter == 'PM'}" @click="filter = 'PM'" data-position="top" data-tooltip="Past Month">PM</span>
      <span class="btn-range tooltipped" :class="{'active-btn': filter == 'LY'}" @click="filter = 'LY'" data-position="top" data-tooltip="Past Year">PY</span>
    </div>
    <div class='row'>
      <div class='col s12 old-text how-balance low-text'>
        <div class='mt-15'>
          Own sales<span class='hide-on-med-and-up'> (incl tips)</span>: 
          <div v-if='!$apollo.loading' class='right old-text how-balance low-text emerald bold-font'>{{stat.sales.totalSales | currency}}</div><br>
          <div v-if='!$apollo.loading' v-show='stat.sales.overallSales != stat.sales.totalSales'>
            All subs sales<span class='hide-on-med-and-up'> (incl sub-accounts)</span>: 
            <div class='old-text low-text right emerald bold-font'>{{stat.sales.overallSales | currency}}</div>
            <br>
            Card sales: 
            <div class='old-text low-text right emerald bold-font'>{{stat.sales.totalCardSalesAcrossAllAccounts | currency}}</div>
            <br>
            Check sales: 
            <div class='old-text low-text right emerald bold-font'>{{stat.sales.totalCheckSalesAcrossAllAccounts | currency}}</div>
          </div>
        </div>
        <div v-show='showTips'>
          My Card tips: 
          <div  v-if='!$apollo.loading' class='old-text how-balance low-text right emerald bold-font'>{{stat.cardTips | currency}}</div><br>
          My Check's tips: 
          <div  v-if='!$apollo.loading' class='old-text how-balance low-text right emerald bold-font'>{{stat.checkTips | currency}}</div>
          <div v-if='!$apollo.loading && stat.allSubsTips > 0'>
            All subs tips:
            <div  v-if='!$apollo.loading' class='old-text how-balance low-text right emerald bold-font'>{{stat.allSubsTips | currency}}</div>
          </div>
        </div>
        <div v-if='!$apollo.loading'>
          <section v-if="stat.sales.showNps">
            NPS: 
            <div class='right old-text how-balance low-text emerald bold-font'>
              {{stat.sales.nps}}
            </div><br>
          </section>
          <section v-else>
            Votes:
            <div v-if='!$apollo.loading' class='right old-text how-balance low-text emerald bold-font'>
              {{stat.sales.upVotes}} <i class="far fa-thumbs-up grey-text"></i> {{stat.sales.downVotes}} <i class="far fa-thumbs-down grey-text"></i>
            </div><br>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import gql from 'graphql-tag'
  export default {
    props: ['showTips'],
    apollo: {
      stat: {
        query: gql`query($filter:  DateFilter!) {
          merchant { stat(filter: $filter) { sales { totalSales overallSales totalCardSalesAcrossAllAccounts totalCheckSalesAcrossAllAccounts upVotes downVotes showNps nps} cardTips checkTips allSubsTips  } }
        }`,
        variables() {
          return {
            filter: this.filter
          }
        },
        update: data => data.merchant.stat
      }
    },
    data() {
      return {
        stat: {},
        filter: "TODAY"
      }
    },
  }
</script>
<style scoped>
  .show-balance {
    font-size: 2em;
  }
  .btn-range {
    color: grey;
    cursor: pointer;
    font-size: 0.9em;
    margin-left: 8px;
  }
  .active-btn {
    color: #00ab8e;
    border: 1px solid #00ab8e;
    padding: 2px 3px;
  }
</style>
